import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  Typography,
  ListItemText,
  LinearProgress,
} from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { useSelector } from "react-redux";

const ChatBox = ({ handleSend, input, setInput, isLoading, messages }) => {
  const [listHeight, setListHeight] = useState(0);

  const endOfMessagesRef = useRef(null);

  const rfpName = useSelector((state) => state.tabs.rfpName);

  const calculateListHeight = () => {
    const availableHeight = 300;
    setListHeight(availableHeight);
  };

  useEffect(() => {
    calculateListHeight();
    window.addEventListener("resize", calculateListHeight);
    return () => {
      window.removeEventListener("resize", calculateListHeight);
    };
  }, []);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      {isLoading && <LinearProgress color="secondary" />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: listHeight,
        }}
      >
        <Box flexGrow={1} overflow="auto">
          <List>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: message.fromSender
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    backgroundColor: message.fromSender ? teal[50] : grey[100],
                    borderRadius: 1,
                    maxWidth: "75%",
                  }}
                >
                  <ListItemText
                    sx={{
                      textWrap: "wrap",
                    }}
                  >
                    <Typography variant="body2">{message.text}</Typography>
                  </ListItemText>
                </Paper>
              </ListItem>
            ))}
            <div ref={endOfMessagesRef} />
          </List>
        </Box>

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSend();
          }}
        >
          <Box sx={{ display: "flex", columnGap: 1 }}>
            <TextField
              size="small"
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={`Ask anything about ${rfpName}`}
              sx={{
                "& .MuiInputBase-input": { fontSize: "0.875rem" },
              }}
            />
            <Button
              size="small"
              sx={{ minWidth: 100 }}
              type="submit"
              color="primary"
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChatBox;
