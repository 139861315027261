import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveTab, setRfpName } from "../../redux/slices/tabs";

const File = ({ file }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileName =
    file?.name.substring(0, file?.name.lastIndexOf(".")) || file?.name;

  const handleCardClick = () => {
    navigate(`/analyze/${fileName}`);
    dispatch(setActiveTab("analyze"));
    dispatch(setRfpName(fileName));
  };
  return (
    <Card sx={{ width: "100%" }}>
      <CardActionArea onClick={handleCardClick}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <DescriptionIcon fontSize="large" />
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="body2"
              color="textPrimary"
            >
              {file.name}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
};

export default File;
