import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import ChatBox from "./Chatbox";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { Subtitle } from "../../components/Subtitle";
import { labels } from "../../constants/labels";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PolicyIcon from "@mui/icons-material/Policy";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import axios from "axios";

const suggestions = (rfpName) => [
  {
    text: `What is the value of ${rfpName}?`,
    icon: <MonetizationOnIcon />,
  },
  {
    text: "Which state has the highest number of locations to be enabled?",
    icon: <LocationOnIcon />,
  },
  {
    text: "Which product has the highest quantity requested?",
    icon: <AddShoppingCartIcon />,
  },
  { text: "Which legal clause is the most restrictive?", icon: <PolicyIcon /> },
  {
    text: "How many units are requested across all products?",
    icon: <AdUnitsIcon />,
  },
];

const Query = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const rfpName = useSelector((state) => state.tabs.rfpName);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("query"));
  }, [dispatch]);

  const handleSuggestionClick = (suggestion) => {
    handleSend(suggestion);
  };

  const handleSend = async (suggestion) => {
    if (input.trim() || suggestion) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: suggestion ? suggestion : input,
          fromSender: true,
          loading: false,
        },
      ]);
      setInput("");

      try {
        setIsLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_QUERY_API}/query`,
          {
            query: input,
          }
        );

        const apiResponse = {
          fromSender: false,
          text: response?.data?.response,
        };
        setMessages((prevHistory) => [...prevHistory, apiResponse]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          fromSender: false,
          text: "Something went wrong. Please try again later.",
        };
        setMessages((prevHistory) => [...prevHistory, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Subtitle text={labels.queryPageTitle} />
      <Typography color="textPrimary" gutterBottom variant="body2">
        {labels.queryPageDescription}
      </Typography>

      <Grid container spacing={2}>
        <Grid size={8}>
          <Paper sx={{ p: 1, mt: 1 }}>
            <ChatBox
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              messages={messages}
              setMessages={setMessages}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
            />
          </Paper>
        </Grid>
        <Grid size={4}>
          <Paper sx={{ height: "100%" }} variant="outlined">
            <Typography p={1} variant="body2" fontWeight={500}>
              References
            </Typography>
          </Paper>
        </Grid>
        <Grid size={8}>
          <Typography mb={2} variant="body2" fontWeight={500}>
            Suggestions
          </Typography>
          <Grid container spacing={1}>
            {suggestions(rfpName)?.map((item, index) => (
              <Grid key={index} size={4}>
                <CardActionArea
                  onClick={() => handleSuggestionClick(item.text)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <Typography variant="body2">{item.text}</Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid size={4}>
          <Paper sx={{ height: "100%" }} variant="outlined">
            <Typography p={1} variant="body2" fontWeight={500}>
              Below the hood
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Query;
