import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Subtitle } from "../Subtitle";
import { labels } from "../../constants";
import PropTypes from "prop-types";

export const columns = [
  { field: "location_id", headerName: "ID", width: 150 },
  { field: "country", headerName: "Country", width: 150 },
  { field: "address", headerName: "Address", flex: 1 },
];

const Locations = ({ locations }) => {
  return (
    <Box pt={4}>
      <Subtitle text={labels.locationsToBeEnabled} />
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        getRowId={(row) => row?.location_id}
        rows={locations}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default Locations;
