import React, { useCallback, useEffect, useState } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Box,
  CircularProgress,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Subtitle } from "../../components/Subtitle";
import { IconText } from "../../components/IconText";
import { labels } from "../../constants";
import { storage } from "../Login/firebase";
import FileUploadStatus from "../../components/FileUploadStatus/FileUploadStatus";
import { File } from "../../components/File";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { useFiles } from "../../hooks/useFiles";
import { firebaseErrors } from "../../constants/errors";
import { setActiveStep } from "../../redux/slices/steps";

const Upload = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadError, setFileUploadError] = useState("");
  const [progress, setProgress] = useState(0);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveTab("upload"));
    dispatch(setActiveStep(0));
  }, [dispatch]);

  const { filesFromStorage, loading, error } = useFiles(
    allFilesUploaded ? uploadedFiles : []
  );

  const uploadFile = (file, resolve) => {
    setFileUploadError("");
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        setFileUploadError(error.code);
        resolve(false);
      },
      () => {
        resolve(true);
      }
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFiles(acceptedFiles);
    setAllFilesUploaded(false);
    const uploadPromises = acceptedFiles.map(
      (file) =>
        new Promise((resolve) => {
          uploadFile(file, resolve); // Pass resolve to handle async logic
        })
    );

    // Wait for all files to finish uploading
    Promise.all(uploadPromises).then(() => {
      setAllFilesUploaded(true); // Set allFilesUploaded to true after all files are uploaded
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}

      <Subtitle sx={{ mt: 2 }} text={labels.uploadFiles} />
      <Typography color="textPrimary" variant="body2" gutterBottom>
        {labels.uploadFilesDescription}
      </Typography>

      <Paper sx={{ p: 4, mt: 1 }}>
        {fileUploadError && (
          <Alert sx={{ mb: 1 }} severity="error">
            {firebaseErrors[fileUploadError]}
          </Alert>
        )}
        {uploadedFiles?.length > 0 &&
          uploadedFiles?.map(
            (file, index) =>
              progress < 100 &&
              !fileUploadError && (
                <FileUploadStatus key={index} file={file} progress={progress} />
              )
          )}

        <Paper
          variant="contained"
          sx={{ display: "flex", height: 200, bgcolor: grey[100] }}
          {...getRootProps()}
        >
          <Box sx={{ m: "auto" }}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body2">
                Drag and drop your files here
              </Typography>
            ) : (
              <IconText
                text={labels.uploadFilesDragAndDrop}
                icon={<FileUploadIcon />}
              />
            )}
          </Box>
        </Paper>
      </Paper>
      {filesFromStorage?.length > 0 && (
        <Subtitle mt={2} text={labels.previouslyUploaded} />
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          {filesFromStorage?.map((file, index) => (
            <Grid size={{ sm: 3, md: 3, lg: 3, xl: 3 }} key={index}>
              <File file={file} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

Upload.propTypes = {};

export default Upload;
