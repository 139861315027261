export const labels = {
  appName: "RFP Navigator",
  uploadFiles: "Upload file(s)",
  uploadFilesDragAndDrop: "Click to upload or drag and drop your file(s) here",
  productsRequested: "Products requested",
  locationsToBeEnabled: "Locations to be enabled",
  legalClauses: "Legal clauses",
  fileUploaded: "file uploaded",
  rfpDue: "RFP Due:",
  previouslyUploaded: "Previously uploaded",
  queryPageTitle: "RFP Assisant",
  queryPageDescription:
    "Interactively explore and inquire about uploaded Requests for Proposals (RFPs) using our chat feature. Get instant responses to your queries, helping you quickly navigate and understand relevant RFP details.",
  uploadFilesDescription:
    "Easily upload your RFPs and proceed to the analysis phase. This will streamline the process by allowing you to upload files quickly, guiding you to the next step where you can explore and analyze your RFP data.",
  askRfpAssistant: "Ask RFP Assistant",
};
